import * as amplitude from '@amplitude/analytics-browser';
import { datadogLogs } from '@datadog/browser-logs';
import { RumActionEventDomainContext, datadogRum } from '@datadog/browser-rum';
import {
  addBreadcrumb,
  captureException,
  init,
  reactRouterV5Instrumentation,
  setUser as sentrySetUser,
} from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { generateLogUtils, getVersion, SetupParams } from './shared';

const { exceptionLogger, log, logException } = generateLogUtils({
  addBreadcrumb,
  captureException,
});

const getServiceName = () => {
  if (
    window.matchMedia('(display-mode: standalone)').matches ||
    // @ts-expect-error
    window.navigator.standalone === true
  ) {
    return 'pwa';
  }
  return 'desktop';
};

const setupAmplitude = () => {
  amplitude.init(AMPLITUDE_API_KEY, {
    minIdLength: 1,
    defaultTracking: {
      attribution: false,
      pageViews: true,
      sessions: true,
      formInteractions: false,
      fileDownloads: false,
    },
    appVersion: getVersion(),
  });
};

const setupSentry = (params: SetupParams = {}) => {
  const { history } = params;

  if (history == null)
    throw new TypeError('`history` is required on web environments');

  init({
    dsn: 'https://6473e9d59b4e4260bb065b5fa01ccf35@sentry.io/1246566',
    environment: ENVIRONMENT,
    release: RELEASE_NAME,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(history),
        // We must first deploy a backend version that allows sentry-trace header
        // tracingOrigins: ['localhost', API_URL, API_URL_WS, /^\//u],
      }),
    ],
    tracesSampleRate: 0,
  });
};

const setupDatadog = () => {
  const clientToken = 'pub3e2d694a2cc0cce94e87fa099073cdb0';
  datadogLogs.init({
    clientToken,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
  });

  datadogRum.init({
    applicationId: '409af07a-ab6c-4955-b3db-050eb7b7a69a',
    clientToken,
    site: 'datadoghq.com',
    service: getServiceName(),
    env: ENVIRONMENT,
    version: getVersion(),
    sampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    allowedTracingOrigins: [API_URL, API_URL_WS, PAPI_URL, PAPI_URL_WS],
    premiumSampleRate: 100,
    beforeSend(event, context) {
      if (event.type === 'action' && event.action.target != null) {
        const ctx = context as RumActionEventDomainContext;
        if (
          ctx.events != null &&
          ctx.events.length > 0 &&
          ctx.events[0] != null &&
          ctx.events[0].target != null
        ) {
          const target = ctx.events[0].target as HTMLElement;
          const { actionName, actionContext } = target.dataset;
          if (actionName != null) {
            event.action.target.name = actionName;
            event.context = {
              context: actionContext,
              value: target.textContent,
            };
            amplitude.track(event.action.target.name, event.context);
          }
        }
      }
      return true;
    },
  });
  datadogRum.startSessionReplayRecording();
};

export interface SetUserParams {
  readonly id?: string;
  readonly name?: string;
  readonly email?: string;
  readonly electronVersion?: string;
}

const setUser = (params: SetUserParams | null) => {
  if (ENABLE_AMPLITUDE === 'true' && params != null) {
    const identifyObj = new amplitude.Identify();
    if (params.id != null) {
      identifyObj.set('id', params.id);
    }
    if (params.name != null) {
      identifyObj.set('name', params.name);
    }
    identifyObj.set('service', getServiceName());
    identifyObj.set('env', ENVIRONMENT);

    amplitude.identify(identifyObj, { user_id: params.email });
  }
  if (ENABLE_SENTRY === 'true') {
    sentrySetUser(
      params == null
        ? null
        : {
            id: params.id,
            username: params.name,
            email: params.email,
            electronVersion: params.electronVersion,
          },
    );
  }
  if (ENABLE_DATADOG === 'true') {
    const user =
      params == null
        ? {}
        : {
            id: params.id,
            name: params.name,
            email: params.email,
            electronVersion: params.electronVersion,
          };
    datadogLogs.setUser(user);
    datadogRum.setUser(user);
  }
};

/**
 *
 * @param actionName The action name that was triggered
 * @param params Additional context
 */
const trackAction = (actionName: string, params: Record<string, unknown>) => {
  if (ENABLE_DATADOG === 'true') {
    datadogRum.addAction(actionName, params);
  }
  if (ENABLE_AMPLITUDE === 'true') {
    amplitude.track(actionName, params);
  }
};

export {
  exceptionLogger,
  log,
  logException,
  setupAmplitude,
  setupDatadog,
  setupSentry,
  setUser,
  trackAction,
};

export {
  createReduxEnhancer,
  getCurrentHub,
  startTransaction,
  withProfiler,
} from '@sentry/react';

export { getActiveTransaction, SpanStatus } from '@sentry/tracing';
export type { Transaction } from '@sentry/types';
