import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import createSafeContext from '@paradigm/utils/createSafeContext';
import { Drfq } from '@paradigm/features/src/unified-rfqs/entities/drfq/domain';
import {
  ChangeLegPriceArgs,
  NullableTwoWay,
} from '#/unified-rfqs/ui/drfq//quote-builder/form-reducer';
import { getValidValueOrNull } from './utils';

interface Prefill {
  readonly id: Drfq['id'];
  readonly price?: NullableTwoWay;
  readonly legs?: Record<string, ChangeLegPriceArgs>;
}

type PrefillState = EntityState<Prefill, string>;

interface PrefillActions {
  changePrice: (price: NullableTwoWay, id: Drfq['id']) => void;
  changeLegPrice: (leg: ChangeLegPriceArgs, id: Drfq['id']) => void;
}

const adapter = createEntityAdapter<Prefill>();
const selectors = adapter.getSelectors();

const INITIAL_STATE: PrefillState = adapter.getInitialState();

const [useQuotePrefillState, StateProvider] =
  createSafeContext<PrefillState>('PrefillState');

export const [useQuotePrefillActions, ActionsProvider] =
  createSafeContext<PrefillActions>('PrefillActions');

interface Props {
  readonly children: ReactNode;
}

export const QuotePrefillProvider = ({ children }: Props) => {
  const [state, setState] = useState(INITIAL_STATE);

  const actions = useMemo(() => createActions(setState), []);

  return (
    <StateProvider value={state}>
      <ActionsProvider value={actions}>{children}</ActionsProvider>
    </StateProvider>
  );
};

function createActions(
  setState: Dispatch<SetStateAction<PrefillState>>,
): PrefillActions {
  return {
    changePrice(price, id) {
      setState((state) =>
        adapter.upsertOne(state, {
          id,
          price: [getValidValueOrNull(price[0]), getValidValueOrNull(price[1])],
        }),
      );
    },
    changeLegPrice(leg, id) {
      setState((state) => {
        const prefill = selectors.selectById(state, id);

        return adapter.upsertOne(state, {
          id,
          legs: {
            ...prefill?.legs,
            [`${leg.index}-${leg.side}`]: leg,
          },
        });
      });
    },
  };
}

export function useQuotePrefill(rfqId: Drfq['id']) {
  const state = useQuotePrefillState();

  return selectors.selectById(state, rfqId);
}
